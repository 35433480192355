<template>
  <page-main
    title="Descriptors"
    nextText="Your Interests"
    buttonTabIndex="3"
    eventName="nav"
    @nav="nav('/Career')"
    @save="save()"
  >
    <template v-slot:aside>
      How do you see yourself? If you asked your friends or co-workers the same
      question, how would they describe you? What roles have you taken on in the
      past?
      <ul>
        <li>
          Descriptors are words that describe how you and others perceive you
          when you are operating at your best. There are two categories below:
          (1) Adjectives and (2) Roles. We recommend you select up to three words
          for each category.
        </li>
        <li>
          This will help you describe your personal brand - this is essential
          for setting up the foundation of your resume and how you describe
          yourself to others.
        </li>
      </ul>
    </template>
    <template v-slot:nav>
      <v-system-bar color="accent" height="5" class="mt-4"></v-system-bar>
      <modal cardTitle="Example" ctaText="Need an example?">
        <ul class="font-italic">
          <li class="mb-4">
            <span class="font-weight-bold">Example #1:</span>
            <ul>
              <li>
                <span class="font-weight-bold"
                  >How do you describe yourself (Adjectives):</span
                >
                Decisive; Innovative; Hard-Working
              </li>
              <li>
                <span class="font-weight-bold">Roles:</span>
                Engineer; Problem Solver; Leader
              </li>
            </ul>
          </li>
          <li class="mb-4">
            <span class="font-weight-bold">Example #2:</span>
            <ul>
              <li>
                <span class="font-weight-bold"
                  >How do you describe yourself (Adjectives):</span
                >
                Detail-Oriented; Diligent; Collaborative
              </li>
              <li>
                <span class="font-weight-bold">Roles:</span>
                Banker; Manager
              </li>
            </ul>
          </li>
        </ul>
      </modal>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <r-tip-container :tips="tips"></r-tip-container>
      </modal>
    </template>
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Type here to add or use drop down to select (one at a time)"
            titleText="How do you describe yourself? (Adjectives)"
            :optionsList="baseAdjectives"
            :selected.sync="selectedAdjectives"
            autofocus
            tabindex="1"
            :maxSelections="25"
          ></r-multi-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Type here to add or use drop down to select (one at a time)"
            titleText="What roles summarize who you are or what you do? (Roles)"
            :optionsList="baseRoles"
            :selected.sync="selectedRoles"
            tabindex="2"
            :maxSelections="25"
          ></r-multi-select>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
// import ValueDisplay from "../components/ValueDisplay";
import * as Display from "../utility/display";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";

// @ is an alias to /src
export default {
  name: "Adjectives",
  data: () => ({}),
  components: {},
  methods: {
    save() {
      this.buildDisplay();
      this.saveAdjective();
      this.saveRole();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapActions("adjective", ["saveAdjective"]),
    ...mapActions("role", ["saveRole"]),
    buildDisplay() {
      let adjValue = Display.BuildCommaString("", this.selectedAdjectives);
      let roleValue = Display.BuildCommaString("", this.selectedRoles);

      this.adjectiveDisplay = adjValue;
      this.roleDisplay = roleValue;

      return [adjValue, roleValue];
    },
  },
  mounted() {},
  computed: {
    ...mapFields("adjective", [
      "selectedAdjectives",
      "baseAdjectives",
      "adjectiveDisplay",
    ]),
    ...mapFields("role", ["selectedRoles", "baseRoles", "roleDisplay"]),
    displayValue: function () {
      return this.buildDisplay();
    },
    tips() {
      return [
        "We recommend you include three descriptors for each category, and do not include more than five. Too many descriptors can be distracting.",
        "The roles you choose will summarize either what you are in terms of your work/profession or what role you assume when working with others. When selecting your roles, limit yourself to no more than three.",
      ];
    },
  },
};
</script>